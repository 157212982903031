export function useResourcesApi() {
  const { get } = useApi()

  function getLanguages(id: string | undefined = undefined, all: boolean | undefined = false) {
    if (id) {
      return get(`resources/languages/${id}`)
    }
    return get(`resources/languages?all=${all}`)
  }

  function getTimezones() {
    return get('resources/timezones')
  }

  function getCountries() {
    return get('resources/countries')
  }

  function getProvinces(id: string) {
    return get(`resources/countries/${id}/provinces`)
  }

  function getServices(id: string | undefined, all: boolean | undefined = false) {
    if (id) {
      return get(`resources/services/${id}`)
    }
    return get(`resources/services?all=${all}`)
  }

  function getPersons(id: string | undefined) {
    // if (id)
    //   return get(`members/${id}`)
    return get('members')
  }

  function getSpecializations() {
    return get('resources/specializations')
  }

  function getOffices() {
    return get('resources/offices')
  }

  function getPriceProfiles(id: string) {
    if (id) {
      return get(`resources/priceprofiles/${id}`)
    }
    return get('resources/priceprofiles')
  }

  function getDeadlineOptions(id: string) {
    if (id) {
      return get(`resources/deadlines/${id}`)
    }
    return get('resources/deadlines')
  }

  function getSettings(id: string | undefined = undefined) {
    if (id) {
      return get(`resources/settings/${id}`)
    }
    return get('resources/settings')
  }

  function getSettingsSchema() {
    return get('resources/settings/schema')
  }

  function getRejectReasons() {
    return get('resources/reject-reasons')
  }

  return {
    getLanguages,
    getTimezones,
    getCountries,
    getProvinces,
    getServices,
    getPersons,
    getSpecializations,
    getOffices,
    getPriceProfiles,
    getDeadlineOptions,
    getSettings,
    getSettingsSchema,
    getRejectReasons,
  }
}
